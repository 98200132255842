import React from 'react'
import { Link } from "react-router-dom";
import { Carousel, Image } from "react-bootstrap";
import "./carrocel.css"
//import img1 from '../assets/banner-BEM-VINDO-METODISTA.jpg'
import img1 from '../assets/oportunidade professores.jpg'
import img2 from '../assets/banner_transfer.jpg'
import img3 from '../assets/REinscrições-mestrados-banner-.jpg'
import img4 from '../assets/banner-idiomschool.jpg'
// import img5 from '../assets/-banner-exame-de-acesso-.jpg'
import img5 from '../assets/banner-transferencia--.jpg'
import img6 from '../assets/banner-assédio .jpg'


function Carrocel() {
  return (

    <Carousel>

      <Carousel.Item>
        <a href='https://portal.mutue.net/candidatura_docente/criar' target='_blank'> 
          <img
            className="d-block w-100"
            src={img1}
            alt="Five slide"
          />
        </a>

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={img2}
          alt="Five slide"
        />
      </Carousel.Item>

      <Carousel.Item>
        <Link className="LinkStyle" to="/escoladeidiomas">
          <a target="_blank" href="https://forms.gle/emXUZmT5Z2HTXX9i8">
            <img
              className="d-block w-100"
              src={img4}
              alt="First slide"
            />
          </a>
        </Link>
      </Carousel.Item>

      {/* <Carousel.Item>
          <a target="_blank" href="https://mutue.ao/register">
            <img
              className="d-block w-100"
              src={img5}
              alt="Five slide"
            />
          </a>
        </Carousel.Item> */}

      {/* <Carousel.Item>
          
            <img
              className="d-block w-100"
              src={img5}
              alt="Five slide"
            />
          
        </Carousel.Item> */}

      {/* <Carousel.Item>
          <a target="_blank" href="https://mutue.ao/register">
              <img
                className="d-block w-100"
                src={img3}
                alt="Five slide"
              />
          </a>
        </Carousel.Item> */}

      <Carousel.Item>
        <Link className="LinkStyle" to="/ouvidoria">
          <img
            className="d-block w-100"
            src={img6}
            alt="sixth slide"
          />
        </Link>
      </Carousel.Item>






      {/* <Carousel.Item>
          <Link className="LinkStyle" to="/poslaboral">
            <img
              className="d-block w-100"
              src={img2}
              alt="Five slide"
            />
          </Link>
        </Carousel.Item> */}

      {/* <Carousel.Item>
          <Link className="LinkStyle" to="/curso22">
            <img
              className="d-block w-100"
              src={img1}
              alt="Five slide"
            />
          </Link>
        </Carousel.Item> */}




      {/* <Carousel.Item>
          <a target="_blank" href="https://mutue.ao/candidatura_docente/inicial">
            <img
              className="d-block w-100"
              src={img1}
              alt="First slide"
            />
          </a>
        </Carousel.Item> */}

      {/* <Carousel.Item>
          <img
            className="d-block w-100"
            src={img2}
            alt="Second slide"
          />
        </Carousel.Item> */}






      {/* <Carousel.Item>
          <img
            className="d-block w-100"
            src={img5}
            alt="Five slide"
          />
        </Carousel.Item> */}

      {/* <Carousel.Item>
          <img
            className="d-block w-100"
            src={img1}
            alt="Fourth slide"
          />
          <Carousel.Caption>
            <h3>Primeiro Slide</h3>
            <p>Universidade Metodista de Angola</p>
          </Carousel.Caption>
        </Carousel.Item> */}



      {/* <Carousel.Item>
          <img
            className="d-block w-100"
            src={img3}
            alt="Third slide"
          />

        </Carousel.Item> */}

    </Carousel>
  );
}

export default Carrocel;